import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

const LEADTOLEASE_API_BASE = `${
  process.env.NEXT_PUBLIC_LEADTOLEASE_API_DOMAIN ||
  'https://reports.r6clients.com'
}/api/v1/`;

interface AddLead {
  corporation_code: string;
  location_code: string;
  unit_id?: string;
  unit_name?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  date_needed: string;
  source?: string;
  comment?: string;
}

interface AddLeadResponse {
  waiting_id: string;
  global_waiting_num: string;
  tenant_id: string;
}

interface UpdateLead {
  corporation_code: string;
  location_code: string;
  tenant_id: string;
  waiting_id: string;
  unit_name?: string;
  unit_id?: string;
  quoted_rate?: number;
  concession_id?: number;
}

interface UpdateLeadResponse {
  waiting_id: string;
}

export interface ValidationErrorResponse {
  status: number;
  data: {
    code: 'validation_failed';
    error: string;
    fields: {
      [field: string]: string[];
    };
    message: string;
    source: string;
  };
}

const baseQuery = fetchBaseQuery({
  baseUrl: LEADTOLEASE_API_BASE,
  prepareHeaders: (headers) => {
    const apiKey = process.env.NEXT_PUBLIC_LEADTOLEASE_API_KEY;

    if (apiKey) {
      headers.set('Authorization', `Bearer ${apiKey}`);
    }

    return headers;
  },
}) as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | ValidationErrorResponse
>;

export const leadToLeaseApi = createApi({
  reducerPath: 'leadToLeaseApi',
  baseQuery,
  endpoints: (build) => ({
    addLead: build.mutation<AddLeadResponse, AddLead>({
      query: ({ corporation_code, ...body }) => ({
        url: `${corporation_code}/leads`,
        method: 'POST',
        body,
      }),
    }),
    updateLead: build.mutation<UpdateLeadResponse, UpdateLead>({
      query: ({ corporation_code, ...body }) => ({
        url: `${corporation_code}/leads`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const useAddLeadMutation = leadToLeaseApi.endpoints.addLead.useMutation;
export const useUpdateLeadMutation =
  leadToLeaseApi.endpoints.updateLead.useMutation;

export default leadToLeaseApi.reducer;
