import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from './index';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { setRapidstorToken } from './authSlice';

const RAPIDSTOR_API_BASE =
  process.env.NEXT_PUBLIC_RAPIDSTOR_API_BASE ||
  'https://prod.rapidstorapp.com/api/v1';

type TokenSelector = (state: RootState) => string | null;
type TokenSetter = ActionCreatorWithPayload<string | null>;

export const generateRapidstorQuery = (
  baseUrl: string,
  apiKey: string,
  selectToken: TokenSelector,
  setToken: TokenSetter
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

  const authenticateQuery: FetchArgs = {
    url: 'authenticate',
    method: 'POST',
    body: {
      token: apiKey,
    },
    responseHandler: 'text',
  };

  const rapidstorFetchBase: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const token = selectToken(api.getState() as RootState);

    if (!token) {
      const authResp = await baseQuery(authenticateQuery, api, extraOptions);

      api.dispatch(setToken(authResp.data as string));
    }

    const query = await baseQuery(args, api, extraOptions);

    if (
      query.error &&
      (query.error.status === 403 || query.error.status === 401) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (query.error.data as any).error === 'Invalid Token'
    ) {
      try {
        const authResp = await baseQuery(authenticateQuery, api, extraOptions);

        api.dispatch(setToken(authResp.data as string));

        return await baseQuery(args, api, extraOptions);
      } catch (e) {
        console.error(e);
      }
    }

    return query;
  };

  return rapidstorFetchBase;
};

export const rapidstorBaseQuery = generateRapidstorQuery(
  RAPIDSTOR_API_BASE,
  process.env.NEXT_PUBLIC_RAPIDSTOR_API_KEY as string,
  (state: RootState) => state.auth.tokens.rapidstor,
  setRapidstorToken
);
