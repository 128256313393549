import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

type NotificationType = 'default' | 'success' | 'danger';

export interface Notification {
  id: number;
  type: NotificationType;
  message: string;
  duration?: number;
  canClose?: boolean;
}

interface NotifyState {
  notifications: Notification[];
}

const initialState: NotifyState = {
  notifications: [],
};

const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    pushNotification: (
      state,
      action: PayloadAction<Omit<Notification, 'id'>>
    ) => {
      state.notifications.push({
        id: parseInt(Math.random().toString().split('.')[1], 10),
        ...action.payload,
      });
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.payload
      );
    },
  },
});

export const { pushNotification, removeNotification } = notifySlice.actions;

export default notifySlice.reducer;

export const selectNotifications = (state: RootState): Notification[] =>
  state.notify.notifications;
