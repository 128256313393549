import { createApi } from '@reduxjs/toolkit/query/react';
import { createSelector } from '@reduxjs/toolkit';
import { rapidstorBaseQuery } from './generateRapidstorQuery';
import { Insurance, transformInsurance } from '../entities/Insurance';
import { RapidStorInsurance, RapidStorResponse } from '../services/rapidstor';

export const insuranceApi = createApi({
  reducerPath: 'insuranceApi',
  baseQuery: rapidstorBaseQuery,
  endpoints: (build) => ({
    getInsurance: build.query<Insurance[], string>({
      query: (locationCode: string) => `${locationCode}/insurance`,
      transformResponse: (resp: RapidStorResponse<RapidStorInsurance[]>) =>
        resp.results.map((i) => transformInsurance(i)),
    }),
  }),
});

export const useGetInsuranceQuery =
  insuranceApi.endpoints.getInsurance.useQuery;

export const selectInsurance = (locationCode: string | null) =>
  locationCode
    ? createSelector(
        insuranceApi.endpoints.getInsurance.select(locationCode),
        (result) => result.data || []
      )
    : () => [];

export default insuranceApi.reducer;
