import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { rapidstorBaseQuery } from './generateRapidstorQuery';
import { RapidStorResponse, RapidStorUnitList } from '../services/rapidstor';
import Descriptor, { descriptorTransformer } from '../entities/Descriptor';
import UnitType, { unitTypeTransformer } from '../entities/UnitType';
import { RootState } from './index';

interface UnitList {
  descriptors: Descriptor[];
  unitTypes: UnitType[];
}

export const unitListApi = createApi({
  reducerPath: 'unitListApi',
  baseQuery: rapidstorBaseQuery,
  keepUnusedDataFor: 3600,
  tagTypes: ['UnitList'],
  endpoints: (builder) => ({
    getUnitList: builder.query<UnitList, string>({
      query: (locationCode) => `locations/${locationCode}/unit-list`,
      providesTags: ['UnitList'],
      transformResponse: (resp: RapidStorResponse<RapidStorUnitList>) => ({
        descriptors: resp.results.descriptors
          .map((d) => descriptorTransformer(d))
          .filter((d) => d.unitTypes.length > 0 && !d.hidden && d.enabled),
        unitTypes: resp.results.unitTypes.map((u) => unitTypeTransformer(u)),
      }),
    }),
  }),
});

export const useGetUnitListQuery = unitListApi.endpoints.getUnitList.useQuery;

export default unitListApi.reducer;

export const selectDescriptors = createSelector(
  (state: RootState, locationCode: string) => {
    if (!locationCode) {
      return { data: null };
    }

    return unitListApi.endpoints.getUnitList.select(locationCode)(state);
  },
  (state: RootState, locationCode: string) => locationCode,
  (result) => (result.data ? result.data.descriptors : [])
);

export const selectDescriptor = createSelector(
  (state: RootState) => selectDescriptors(state, state.quote.location ?? ''),
  (state: RootState, descriptorId: string) => descriptorId,
  (descriptors, descriptorId) =>
    descriptors.find((d) => d._id === descriptorId) ?? null
);

export const selectUnitTypes = (locationCode: string | null) =>
  locationCode
    ? createSelector(
        unitListApi.endpoints.getUnitList.select(locationCode),
        (result) => (result.data ? result.data.unitTypes : [])
      )
    : () => [];

export const selectUnitType = (
  locationCode: string | null,
  unitTypeId: string | null
) =>
  createSelector(
    selectUnitTypes(locationCode),
    (unitTypes) => unitTypes.find((u) => u._id === unitTypeId) ?? null
  );
