import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistedState } from 'redux-persist/lib/types';
import quoteReducer from './quoteSlice';
import authReducer from './authSlice';
import notifyReducer from './notifySlice';
import { locationApi } from './locationSlice';
import { unitListApi } from './unitListSlice';
import { insuranceApi } from './insuranceSlice';
import { leadToLeaseApi } from '../services/leadtolease';
import { reservationApi } from '../services/reservation';
import { backendApi } from '../services/backend';

const MIGRATION_VERSION = 6;

/**
 * Redux-Persist migrations
 * If a breaking change to redux state is made increment the version number below
 *
 * If a field is added to state and redux hydrates existing state, that field or object
 * will not be present.
 */
const migrations: { [k: string]: (state: PersistedState) => PersistedState } =
  {};

for (let i = 0; i <= MIGRATION_VERSION; i++) {
  migrations[i] = () => undefined;
}

const persistConfig = {
  key: 'root',
  version: MIGRATION_VERSION,
  storage,
  migrate: createMigrate(migrations, { debug: true }),
  stateReconciler: autoMergeLevel2,
  blacklist: ['notify'],
};

const rootReducer = combineReducers({
  quote: quoteReducer,
  locationApi: locationApi.reducer,
  unitListApi: unitListApi.reducer,
  insuranceApi: insuranceApi.reducer,
  leadToLeaseApi: leadToLeaseApi.reducer,
  reservationApi: reservationApi.reducer,
  backendApi: backendApi.reducer,
  auth: authReducer,
  notify: notifyReducer,
});

const store = configureStore({
  reducer: persistReducer<RootState>(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      locationApi.middleware,
      unitListApi.middleware,
      insuranceApi.middleware,
      leadToLeaseApi.middleware,
      reservationApi.middleware,
      backendApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
